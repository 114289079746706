import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

var password = "Armadillo";
if (prompt("Enter password:", "") === password) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
}
